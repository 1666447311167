































































































































































































































































































































































































































import { ref, computed, watch, onMounted } from '@vue/composition-api';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { extend, ValidationProvider } from 'vee-validate';
import { required, min, confirmed, digits } from 'vee-validate/dist/rules';
import axios from 'axios';
import { useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/user/actions';
import { onLogin } from '@/vue-apollo';
import { addStakeholderToOrg } from '@/api/organizationApi';

const { loginUser, updateForceChangedPwdFlag } = useUserActions([
  ActionTypes.loginUser,
  'updateForceChangedPwdFlag'
]);

extend('phone', {
  validate: value => {
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber ? phoneNumber.isValid() : false;
  },
  message: 'Please enter a valid phone number'
});

// Extend vee-validate rules
extend('required', {
  ...required,
  message: 'This field is required'
});
extend('min', {
  ...min,
  message: 'Password must be at least {length} characters'
});
extend('confirmed', {
  ...confirmed,
  message: 'Passwords do not match'
});
extend('digits', {
  ...digits,
  message: 'Must be exactly {length} digits'
});

// Mock users
const mockUsers = [
  { phone: '+15106765861', email: 'dericklee510@gmail.com', password: 'password' },
  { phone: '+447911123456', email: 'jane@example.com', password: 'password456' },
  { email: 'bob@example.com', password: 'password789' },
  { phone: '+16505551234', password: 'password101' }
];

const phoneRegex = /^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

function validatePhoneNumber(phone) {
  const isValid = phoneRegex.test(phone);
  return isValid;
}

export function isValidUSPhoneNumber(phone) {
  // This regex allows for various formats including (510) 676-5861
  const phoneRegex = /^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?[2-9]\d{2}[-.\s]?\d{4}$/;
  return phoneRegex.test(phone);
}

export default {
  components: {
    ValidationProvider,
    MSetupPortfolio: () => import('../../views/Portfolio/SetupPortfolio.vue')
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isfromlandingpage: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { emit, root } = context;
    const router = root.$router;
    const route = root.$route;

    const dialogVisible = computed({
      get: () => props.value,
      set: value => emit('input', value)
    });

    const contactInfo = ref('');
    const oneTimePassword = ref('');
    const password = ref('');
    const showPassword = ref(false);
    const showPasswordField = ref(false);
    const statusMessage = ref('');
    const isExistingUser = ref(false);
    const showForgotPasswordDialog = ref(false);
    const confirmEmail = ref('');
    const userInfo = ref({
      firstName: '',
      lastName: '',
      email: '',
      filled: false
    });
    const otpStatusMessage = ref('');
    const otpStatusColor = ref('');
    const otpStatusIcon = ref('');
    const actualOTP = ref(''); // This would be set when the OTP is generated
    const currentStep = ref('initial');
    const verificationCode = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const showConfirmPassword = ref(false);
    const statusColor = ref('');
    const statusIcon = ref('');
    const verificationStatusMessage = ref('');
    const verificationStatusColor = ref('');
    const verificationStatusIcon = ref('');
    const actualVerificationCode = ref('');
    const isVerificationCodeCorrect = ref(false);
    const isOTPCorrect = ref(false);
    const previousPage = ref(null);
    const forgotPasswordEmail = ref('');
    const form = ref(null);
    const isFormValid = ref(true);
    const isJoinWithoutAccount = ref(true);
    const token = ref('');
    const isOtpSent = ref(false);
    const showSetupPortfolioDialog = ref(false);
    const newUser = ref(false);
    const errorMessage = ref('');
    const isLoading = ref(false);
    const currentPage = ref(route.name ?? '');
    const isOtpLoading = ref(false);

    const checkIsFrom = () => {
      if (props.isfromlandingpage) {
        isJoinWithoutAccount.value = false;
      }
    };

    onMounted(() => {
      checkIsFrom();
    });

    const isEmail = computed(() => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(contactInfo.value);
    });

    const phoneNumber = computed(() => {
      if (!isEmail.value) {
        return parsePhoneNumberFromString(contactInfo.value, 'US');
      }
      return null;
    });

    const isPhone = computed(() => {
      return phoneNumber.value?.isValid() || false;
    });

    const countryCallingCode = computed(() => {
      if (isPhone.value) {
        return phoneNumber.value?.countryCallingCode || '1';
      }
      return '';
    });

    const contactType = computed(() => {
      if (isJoinWithoutAccount.value) return 'email';
      if (isEmail.value) return 'email';
      if (isPhone.value) return 'tel';
      return 'text';
    });

    const contactLabel = computed(() => {
      if (isJoinWithoutAccount.value) return 'Email';
      if (isEmail.value) return 'Email';
      if (isPhone.value) return 'Phone';
      return 'Phone or Email';
    });

    const contactPlaceholder = computed(() => {
      if (isJoinWithoutAccount.value) return 'Enter your email';
      if (isEmail.value) return 'Enter your email';
      if (isPhone.value) return 'Enter your phone number';
      return 'Enter your phone or email';
    });

    const contactRules = computed(() => {
      if (isJoinWithoutAccount.value) return 'required|email';
      if (isEmail.value) return 'required|email';
      if (isPhone.value) return 'required|regex:^\\+?[0-9\\s\\-()]+$';
      return 'required';
    });

    const isValidContact = computed(() => {
      if (isJoinWithoutAccount.value) return isEmail.value;
      return isEmail.value || isPhone.value;
    });

    const formatPhoneNumber = (value: string) => {
      const formatter = new AsYouType('US');
      contactInfo.value = formatter.input(value);
    };

    async function formatPhoneNumber2(input) {
      // Remove all non-digit characters except for the '+' at the start
      const cleaned = input.replace(/[^+\d]/g, '');

      // Remove leading +1 or 1 if present
      // eslint-disable-next-line no-nested-ternary
      const normalized = cleaned.startsWith('+1')
        ? cleaned.slice(2)
        : cleaned.startsWith('1')
        ? cleaned.slice(1)
        : cleaned;

      // Extract the area code, first part, and second part of the number
      const match = normalized.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return `+1(${match[1]}) ${match[2]}-${match[3]}`;
      }

      // Return null if the input is not a valid phone number format
      return null;
    }

    const handleInput = (value: string) => {
      errorMessage.value = '';

      if (!isEmail.value && !isJoinWithoutAccount.value && value.match(/^[0-9()+-\s]*$/)) {
        // Remove all non-digit characters (e.g., spaces, parentheses, hyphens)
        const sanitizedValue = value.replace(/\D/g, '');

        // Ensure the value only contains up to 10 digits
        const phoneNumber = sanitizedValue.slice(-10); // Get the last 10 digits

        // Update the contactInfo with the sanitized phone number (last 10 digits)
        contactInfo.value = phoneNumber;

        formatPhoneNumber(contactInfo.value); // Format the sanitized phone number
      }
    };

    const setOTPStatus = (message: string, color: string, icon: string) => {
      otpStatusMessage.value = message;
      otpStatusColor.value = color;
      otpStatusIcon.value = icon;
    };

    const setVerificationStatus = (message: string, color: string, icon: string) => {
      verificationStatusMessage.value = message;
      verificationStatusColor.value = color;
      verificationStatusIcon.value = icon;
    };

    const clearOTPStatus = () => {
      otpStatusMessage.value = '';
      otpStatusColor.value = '';
      otpStatusIcon.value = '';
      errorMessage.value = '';
    };

    const clearVerificationStatus = () => {
      verificationStatusMessage.value = '';
      verificationStatusColor.value = '';
      verificationStatusIcon.value = '';
    };

    const login = async (val): Promise<void> => {
      try {
        const user = await loginUser(val);
        try {
          await onLogin(token.value);
          if (JSON.parse(localStorage.getItem('SelectedOrg'))) {
            const data = {
              user_id: user?._id.toString(),
              organization_ids: [JSON.parse(localStorage.getItem('SelectedOrg'))?._id],
              role: 'admin'
            };
            await addStakeholderToOrg(data);
          }
        } catch (err) {
          console.log(err);
        }
        if (newUser.value) {
          showSetupPortfolioDialog.value = true;
        } else if (localStorage.getItem('program')) {
          router.push({ name: 'setup' });
        } else if (route.params.program_name) {
          emit('submit');
        } else if (route.params.page) {
          window.location.reload();
        } else {
          router.push({ name: 'setup' });
        }
        dialogVisible.value = false;
      } catch (err: any) {
        console.log('Error : ', err);
      }
    };

    async function verifyToken(val) {
      token.value = val;
      try {
        const data = {
          token: val
        };
        const url = `${process.env.VUE_APP_AUTH0}/verify-jwt`;
        // const url = 'http://localhost:3000/stage/auth0/verify-jwt';
        const resp = await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp) {
          login(resp.data.data);
        }
      } catch (error) {
        console.log('Error : ', error);
      }
    }

    const verifyOtp = async data => {
      // const url = 'http://localhost:3000/stage/auth0/verify-otp';
      const url = `${process.env.VUE_APP_AUTH0}/verify-otp`;
      try {
        const response = await axios.post(url, data);
        if (response.status === 200) {
          isOTPCorrect.value = true;
          localStorage.setItem('apollo-refresh-token', response.data.data.refresh_token);
          await verifyToken(response.data.data.access_token);
          return true;
        }
        isOTPCorrect.value = false;
        return false;
      } catch (err) {
        isLoading.value = false;
        isOtpLoading.value = false;
        setOTPStatus('Incorrect OTP. Please try again.', 'red', 'mdi-alert-circle');
        isOTPCorrect.value = false;
        return false;
      }
    };

    const checkOTP = async () => {
      clearOTPStatus();
      if (oneTimePassword.value.length === 6) {
        // isOTPCorrect.value = true;
        isOtpLoading.value = true;
        const phoneNumber = await formatPhoneNumber2(contactInfo.value);
        const data = {
          type: isEmail.value ? 'email_otp_verify' : 'phone_number_verify',
          ...(isEmail.value ? { email: contactInfo.value } : { phone_number: phoneNumber }),
          code: oneTimePassword.value
        };
        const response = await verifyOtp(data);
        if (response) {
          // if (isOTPCorrect.value) {
          setOTPStatus('OTP verified successfully!', 'green', 'mdi-check-circle');
          isOtpLoading.value = false;
          setTimeout(() => {
            currentStep.value = 'loginSuccess';
            // Simulate redirect after 3 seconds
            setTimeout(() => {
              if (previousPage.value) {
                // Logic to redirect to previous page
              } else {
                // Logic to redirect to My Portfolio
                // console.log('Redirecting to My Portfolio');
              }
              emit('close');
            }, 3000);
          }, 1000);
          // } else {
          //   setOTPStatus('Incorrect OTP. Please try again.', 'red', 'mdi-alert-circle');
          // }
        } else {
          setOTPStatus('Incorrect OTP. Please try again.', 'red', 'mdi-alert-circle');
          isOTPCorrect.value = false;
          isOtpLoading.value = false;
        }
      } else {
        isOTPCorrect.value = false;
        isOtpLoading.value = false;
        otpStatusMessage.value = '';
      }
    };

    // eslint-disable-next-line consistent-return
    const signIn = async (signInType, contactData, password) => {
      clearOTPStatus();
      const phoneNumber = await formatPhoneNumber2(contactData);
      const signInData: any = {
        type: signInType,
        ...(isEmail.value ? { email: contactData } : { phone_number: phoneNumber })
      };

      if (isEmail.value && password) {
        signInData.password = password;
      }

      // const url = 'http://localhost:3000/stage/auth0/sign-in';
      const url = `${process.env.VUE_APP_AUTH0}/sign-in`;

      try {
        const resp = await axios.post(url, signInData);
        isLoading.value = false;
        if (resp && resp.status === 200) {
          return resp;
        }

        return false;
      } catch (err: any) {
        isLoading.value = false;
        if (err.response && (err.response.status === 400 || err.response.status === 403)) {
          const errorData = err.response.data;
          errorMessage.value = errorData.error.description;
        }
        return false;
      }
    };

    const signUp = async contactData => {
      clearOTPStatus();
      const phoneNumber = await formatPhoneNumber2(contactInfo.value);
      const signUpData = {
        type: isEmail.value ? 'email_signup' : 'phone_number_signup',
        ...(isEmail.value ? { email: contactData } : { phone_number: phoneNumber })
      };
      // const url = 'http://localhost:3000/stage/auth0/sign-up';
      const url = `${process.env.VUE_APP_AUTH0}/sign-up`;
      try {
        const resp = await axios.post(url, signUpData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        isLoading.value = false;
        if (resp.status === 200) {
          return true;
        }
        return false;
      } catch (err: any) {
        isLoading.value = false;
        if (err.response) {
          const errorData = err.response.data;
          errorMessage.value = errorData.error.description;
        }
        return false;
      }
    };

    const refreshOTP = async () => {
      oneTimePassword.value = '';
      isOTPCorrect.value = false;
      clearOTPStatus();

      if (isOtpSent.value) {
        if (newUser.value) {
          await signUp(contactInfo.value);
        } else {
          // eslint-disable-next-line no-nested-ternary
          const type = isEmail.value
            ? password.value
              ? 'email_signin'
              : 'email_otp_signin'
            : 'phone_number_signin';

          await signIn(type, contactInfo.value, '');
        }
      }
      if (!errorMessage.value) {
        setOTPStatus(
          `New OTP sent to your ${isEmail.value ? 'email' : 'phone'}`,
          'blue',
          'mdi-information'
        );
        setTimeout(clearOTPStatus, 3000);
      }
    };

    const checkVerificationCode = () => {
      clearOTPStatus();
      if (verificationCode.value.length === 6) {
        // TODO: Replace with actual verification code check
        // isVerificationCodeCorrect.value = verificationCode.value === '123456';
        // if (isVerificationCodeCorrect.value) {
        isVerificationCodeCorrect.value = true;
        setVerificationStatus('Verification code correct!', 'green', 'mdi-check-circle');
        setTimeout(clearVerificationStatus, 3000);
        // } else {
        //   setVerificationStatus(
        //     'Incorrect verification code. Please try again.',
        //     'red',
        //     'mdi-alert-circle'
        //   );
        // }
      } else {
        setVerificationStatus(
          'Incorrect verification code. Please try again.',
          'red',
          'mdi-alert-circle'
        );
        isVerificationCodeCorrect.value = false;
        clearVerificationStatus();
      }
    };

    const refreshVerificationCode = () => {
      verificationCode.value = '';
      isVerificationCodeCorrect.value = false;
      setVerificationStatus(
        `New verification code sent to your ${isEmail.value ? 'email' : 'phone'}`,
        'blue',
        'mdi-information'
      );

      // TODO: Replace this with actual verification code generation and sending logic
      // console.log('New Verification Code (for testing):', '123456');

      setTimeout(clearVerificationStatus, 3000);
    };

    const isValidNewAccount = computed(() => {
      return (
        isVerificationCodeCorrect.value &&
        (!newPassword.value ||
          (newPassword.value.length >= 8 && newPassword.value === confirmPassword.value))
      );
    });

    const createAccount = async () => {
      isLoading.value = true;
      clearOTPStatus();
      if (isValidNewAccount.value) {
        // Simulate account creation process
        // setTimeout(() => {
        //   currentStep.value = 'accountCreated';
        //   // Simulate transition to portfolio setup after 3 seconds
        //   setTimeout(() => {
        //     // Logic to start portfolio setup
        //     console.log('Starting portfolio setup');
        //     // You would typically change to a new component or route here
        //   }, 3000);
        // }, 1000);
        let formattedContact: string = contactInfo.value;

        // Format phone number if it's a phone number and not joining without an account
        if (isPhone.value && !isJoinWithoutAccount.value && phoneNumber.value) {
          formattedContact = phoneNumber.value.format('E.164');
        }
        const data: any = {
          type: isEmail.value ? 'email_otp_verify' : 'phone_number_verify',
          ...(isEmail.value ? { email: contactInfo.value } : { phone_number: formattedContact }),
          code: verificationCode.value
        };
        if (isEmail.value && newPassword.value) {
          data.password = newPassword.value;
        }
        const response = await verifyOtp(data);
        isLoading.value = false;
        if (response) {
          currentStep.value = 'accountCreated';
        }
      }
    };

    const goBack = () => {
      currentStep.value = 'initial';
      contactInfo.value = '';
      oneTimePassword.value = '';
      otpStatusMessage.value = '';
      password.value = '';
      newPassword.value = '';
      confirmPassword.value = '';
      showPasswordField.value = false;
      isOtpSent.value = false;
      newUser.value = false;
      errorMessage.value = '';
      isLoading.value = false;
    };

    const checkUserExist = async (data: any) => {
      // const url = 'http://localhost:3000/stage/auth0/check-user-exists';
      const url = `${process.env.VUE_APP_AUTH0}/check-user-exists`;
      try {
        const response = await axios.post(url, data);
        if (
          response.data.message === 'User with given email exists.' ||
          response.data.message === 'User with given phone number exists.'
        ) {
          return true;
        }
        newUser.value = true;
        return false;
      } catch (error) {
        console.log('Error: ', error);
        return false;
      }
    };

    const handleContinue = async () => {
      isLoading.value = true;
      if (isValidContact.value) {
        let formattedContact: string = contactInfo.value;

        // Format phone number if it's a phone number and not joining without an account
        if (isPhone.value && !isJoinWithoutAccount.value && phoneNumber.value) {
          formattedContact = phoneNumber.value.format('E.164');
        }

        if (isJoinWithoutAccount.value) {
          userInfo.value.email = formattedContact;
          // You can add any additional logic here for joining without an account
        } else {
          // const user = mockUsers.find(
          //   u =>
          //     (u.phone && u.phone === formattedContact) ||
          //     (u.email && u.email.toLowerCase() === formattedContact.toLowerCase())
          // );

          try {
            const data = {
              ...(isEmail.value ? { email: contactInfo.value } : { phone_number: formattedContact })
            };
            const user = await checkUserExist(data);
            if (user) {
              const response = await signIn(
                isEmail.value ? 'email_otp_signin' : 'phone_number_signin',
                contactInfo.value,
                ''
              );
              if (response) {
                currentStep.value = 'existingUser';
                await refreshOTP();
                isOtpSent.value = true;
              }
            } else {
              const result = await signUp(contactInfo.value);
              if (result) {
                currentStep.value = 'newUser';
                isOtpSent.value = true;
                refreshVerificationCode();
              }
            }
          } catch (error) {
            console.error('Error checking user or signing up:', error);
          }
        }
      }
    };

    const handleSubmit = async () => {
      isLoading.value = true;
      // This function now only handles password login
      // TODO: Replace with actual password verification
      const response = await signIn('email_signin', contactInfo.value, password.value);
      if (response) {
        currentStep.value = 'loginSuccess';
        verifyToken(response.data.data.access_token);
        emit('close');
      }
      // setTimeout(() => {
      //   // Simulating successful login
      //   currentStep.value = 'loginSuccess';
      //   // Simulate redirect after 3 seconds
      //   setTimeout(() => {
      //     if (previousPage.value) {
      //       // Logic to redirect to previous page
      //       console.log('Redirecting to previous page');
      //     } else {
      //       // Logic to redirect to My Portfolio
      //       console.log('Redirecting to My Portfolio');
      //     }
      //     emit('close');
      //   }, 3000);
      // }, 1000);
    };

    const hidePasswordField = () => {
      showPasswordField.value = false;
      password.value = '';
    };

    const handleForgotPassword = async (data: any) => {
      const url = `${process.env.VUE_APP_AUTH0}/change-password`;
      try {
        const response = await axios.post(url, data);
        if (response.status === 200) {
          return true;
        }
        return false;
      } catch (err) {
        console.log('Error : ', err);
        return false;
      }
    };

    const sendResetPasswordEmail = async () => {
      // TODO: Implement password reset logic
      const data = { email: forgotPasswordEmail.value };
      await handleForgotPassword(data);
      showForgotPasswordDialog.value = false;
    };

    const touchField = () => {
      if (form.value) {
        form.value.validate();
      }
    };

    const toggleJoinWithoutAccount = () => {
      isJoinWithoutAccount.value = !isJoinWithoutAccount.value;
    };

    const invalid = computed(() => {
      return !(userInfo.value.email && userInfo.value.firstName && userInfo.value.lastName);
    });

    const submitPublicProgram = () => {
      isLoading.value = true;
      if (!invalid.value) {
        userInfo.value.filled = true;
        emit('submit');
      }
    };

    const handleEnterKey = () => {
      if (isValidNewAccount.value) {
        createAccount();
      }
    };

    return {
      dialogVisible,
      contactInfo,
      oneTimePassword,
      password,
      showPassword,
      showPasswordField,
      statusMessage,
      isExistingUser,
      showForgotPasswordDialog,
      confirmEmail,
      userInfo,
      isEmail,
      phoneNumber,
      isPhone,
      countryCallingCode,
      contactType,
      contactLabel,
      contactPlaceholder,
      contactRules,
      isValidContact,
      handleInput,
      handleContinue,
      handleSubmit,
      otpStatusMessage,
      otpStatusColor,
      otpStatusIcon,
      checkOTP,
      refreshOTP,
      goBack,
      currentStep,
      verificationCode,
      newPassword,
      confirmPassword,
      showConfirmPassword,
      statusColor,
      statusIcon,
      verificationStatusMessage,
      verificationStatusColor,
      verificationStatusIcon,
      isVerificationCodeCorrect,
      checkVerificationCode,
      refreshVerificationCode,
      createAccount,
      isValidNewAccount,
      isOTPCorrect,
      previousPage,
      hidePasswordField,
      forgotPasswordEmail,
      sendResetPasswordEmail,
      form,
      isFormValid,
      touchField,
      isJoinWithoutAccount,
      toggleJoinWithoutAccount,
      invalid,
      submitPublicProgram,
      handleEnterKey,
      token,
      isOtpSent,
      showSetupPortfolioDialog,
      newUser,
      errorMessage,
      isLoading,
      currentPage,
      isOtpLoading
    };
  }
};
